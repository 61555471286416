<template>
  <div class="caseClip_Course" :style="{ minHeight: `${height}`,}">
    <a-row class="data-count">
      <!-- <a-col :span="6" >
        <div style="padding-right: 20px">
          <a-card :bordered="false" style="height: 130px">
            <div class="data-count-day">
              <div>
                <span>今日标签管理访问人数</span>
              </div>
              <div class="data-count-day-sum">
                <h1 style="font-size: xx-large">{{ ExportNum.today }}</h1>
                <div>
                  <span>昨日</span>&nbsp;&nbsp;&nbsp;<span style="color: black">{{ ExportNum.yesterday }}</span>
                </div>
              </div>
              <a-divider />
              <div class="data-count-day-sum">
                <span>访问总人数&nbsp;&nbsp;&nbsp;<span style="color: black">{{ ExportNum.total }}</span></span>
              </div>
            </div>
          </a-card>
        </div>
      </a-col>
      <a-col :span="6" >
        <div style="padding-right: 20px">
          <a-card :bordered="false" style="height: 130px">
            <div class="data-count-day">
              <div>
                <span>今日标签导航使用人数</span>
              </div>
              <div class="data-count-day-sum">
                <h1 style="font-size: xx-large">{{ ExportUserNum.today }}</h1>
                <div>
                  <span>昨日</span>&nbsp;&nbsp;&nbsp;<span style="color: black">{{ ExportUserNum.yesterday }}</span>
                </div>
              </div>
              <a-divider />
              <div class="data-count-day-sum">
                <span>访问总人数&nbsp;&nbsp;&nbsp;<span style="color: black">{{ ExportUserNum.total }}</span></span>
              </div>
            </div>
          </a-card>
        </div>
      </a-col> -->
      <a-col :span="6" >
        <div style="padding-right: 20px">
          <a-card :bordered="false" style="height: 130px">
            <div class="data-count-day">
              <div>
                <span>今日新建标签数量</span>
              </div>
              <div class="data-count-day-sum">
                <h1 style="font-size: xx-large">{{ ExportWordNum.today }}</h1>
                <div>
                  <span>昨日</span>&nbsp;&nbsp;&nbsp;<span style="color: black">{{ ExportWordNum.yesterday }}</span>
                </div>
              </div>
              <a-divider />
              <div class="data-count-day-sum">
                <span>总标签数量&nbsp;&nbsp;&nbsp;<span style="color: black">{{ ExportWordNum.total }}</span></span>
              </div>
            </div>
          </a-card>
        </div>
      </a-col>
      <a-col :span="6">
        <a-card :bordered="false" style="height: 130px;">
          <div class="data-count-day">
            <div>
              <span>系统标签使用次数</span>
            </div>
            <div class="data-count-day-sum">
              <h1 style="font-size: xx-large">{{ ExportExcelNum.today }}</h1>
              <div>
                <span>昨日</span>&nbsp;&nbsp;&nbsp;<span style="color: black">{{ ExportExcelNum.yesterday }}</span>
              </div>
            </div>
            <a-divider />
            <div class="data-count-day-sum">
              <span>使用总人数&nbsp;&nbsp;&nbsp;<span style="color: black">{{ ExportExcelNum.total }}</span></span>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <!-- <div class="date-day">
      <div class="box-header">
        <h3>标签导航使用人数（日）</h3>
        <a-range-picker v-model="day" @change="day_picker" :disabled-date="disabledDate" />
      </div>
      <div class="box-line" id="dayLine">

      </div>

    </div> -->
    <div style="display: flex;justify-content: space-between;margin-top: 20px;min-height: 500px;height: auto">
      <div class="date-month">
        <div class="box-header">
          <h3>标签使用次数Top10（月）</h3>
          <div>
            <a-month-picker v-model="month[0]"
                            valueFormat="YYYY-MM"
                            :disabled-date="disabledDate"
                            placeholder="开始月份"
                            style="width: 120px"
                            @change="month_picker()"

            />
            ~
            <a-month-picker v-model="month[1]"
                            valueFormat="YYYY-MM"
                            :disabled-date="disabledDate"
                            placeholder="结束月份"
                            style="width: 120px"
                            @change="month_picker()"

            />
          </div>
        </div>
        <a-table style="margin: 20px" :columns="columns" :data-source="tableData" bordered  :pagination="false">

        </a-table>
      </div>
      <div style="flex: 1;">
        <div style="margin-bottom:20px;background: white;padding-bottom: 20px ">
          <div class="box-header" style="border: none">
            <h3>系统标签使用占比</h3>
          </div>
          <div class="box-pie" id="system-pie">

          </div>
        </div>
        <div style="background: white;padding-bottom: 20px">
          <div class="box-header" style="border: none">
            <h3>自建标签使用占比 Top10</h3>
          </div>
          <div class="box-pie" id="tag-pie">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import * as echarts from 'echarts';
import {
  getCaseExportList,
  caseExportNum,
  caseExportExcelNum,
  caseExportWordNum,
  caseExportUserNum,
  caseTagNum, caseSystemTagNum, labelManageNum, labelQueryNum, caseTagNumTop, caseLabelPercent, caseLineQueryList
} from "@/service/caseClip_api";
export default {
  name: "caseClip_Course",
  data(){
    const disabledDate = current => {
      return current && current > moment().endOf('day')
    };
    const disabledMonth = current => {
      return current && current > moment().endOf('month')
    };
    return{
      height:window.innerHeight-64-30 + "px",
      day:[moment().subtract(3,'month').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')],
      month:[moment().subtract(6,'month').format('YYYY-MM'),moment().format('YYYY-MM')],
      disabledDate,
      disabledMonth,
      tableData:[],
      columns:[
        {
          title: '序号',
          dataIndex: 'id',
          align:'center'
        },
        {
          title: '标签名称',
          dataIndex: 'labelContent',
          align:'center'
        },
        {
          title: '使用次数',
          dataIndex: 'count',
          align:'center'
        },
      ],

      dayData: [],
      ExportNum:{},
      ExportUserNum:{},
      ExportWordNum:{},
      ExportExcelNum:{},
      systemTag:[],
    }
  },
  mounted() {
    this.get_caseExportNum()
    this.get_caseExportUserNum()
    this.get_caseExportWordNum()
    this.get_caseExportExcelNum()
    this.getCaseData()
    this.caseTagTopData()
    this.get_tagNum(0)
    this.get_tagNum(1)
  },
  methods:{

    async get_tagNum(type){//今日标签管理访问人数
      const info = await caseLabelPercent(type)
      if (info.code===0){

        switch (type) {
          case 0:
            this.systemTag=[]
            info.data.forEach(value=>{
              this.systemTag.push({
                value:value.count,
                name:value.labelContent,
              })
            })
            this.system_pie()
            break;
          case 1:
            this.tag=[]
            info.data.sort((a,b)=>{
              return( b.count-a.count)
            })
            info.data.forEach((value,index)=>{
              if (index<10){
                this.tag.push({
                  value:value.count,
                  name:value.labelContent,
                })
              }
            })
            this.tag_pie()
            break;
        }
      }
    },

    async get_caseExportNum(){//今日标签管理访问人数
      const info = await labelManageNum()
      if (info.code===0){
        this.ExportNum=info.data
      }
    },
    async get_caseExportUserNum(){ //今日标签导航使用人数
      const info = await labelQueryNum()
      if (info.code===0){
        this.ExportUserNum=info.data
      }
    },
    async get_caseExportWordNum(){ //今日新建标签数量
      const info = await caseTagNum()
      if (info.code===0){
        this.ExportWordNum=info.data
      }
    },
    async get_caseExportExcelNum(){ //系统标签使用次数
      const info = await caseSystemTagNum()
      if (info.code===0){
        this.ExportExcelNum=info.data
      }
    },


    async getCaseData(){
      const data={
        startTime:this.day[0],
        endTime:this.day[1],
      }
      const info=await caseLineQueryList(data)
      if (info.code===0){
        this.dayData=[]
        info.data.forEach(value=>{
          this.dayData.push({
            time:value.days,
            count:value.count
          })
        })
        this.line_day()
        }
    },
    day_picker(date, dateString){
      this.day=dateString
      this.getCaseData()
    },

    async caseTagTopData(){
      const data={
        startTime: this.month[0],
        endTime: this.month[1]
      }
      const info = await caseTagNumTop(data)
      if (info.code===0){
        this.tableData=info.data
      }
    },

    month_picker(date, dateString){
      console.log(this.month)
      if (this.month[1]<this.month[0]){
        this.$message.error('结束日期不能大于开始日期')
      }else {
        this.caseTagTopData()
      }
    },

    line_day(){
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById('dayLine'));
      // 绘制图表
      myChart.setOption({

        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {},
        dataset: [
          {
            source: this.dayData
          },
        ],
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
        },
        series:[
          {
            datasetIndex:0,
            name:'标签导航使用人数',
            type:'line',
            smooth: true,
          }
        ]
      });
    },
    system_pie(){
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById('system-pie'));
      // 绘制图表
      myChart.setOption({

        tooltip: {
          trigger: 'item'
        },

        series: [
          {
            type: 'pie',
            radius: '90%',
            label: {            //饼图图形上的文本标签
              show: true,
              position: 'outside', //标签的位置
              textStyle: {
                fontWeight: 300,
                fontSize: 14    //文字的字体大小
              },
              formatter: function (d) {
                return d.name
              }
            },
            data: this.systemTag,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          },
          {
            type: 'pie',
            radius: '90%',
            label: {            //饼图图形上的文本标签
              show: true,
              position: 'inside', //标签的位置
              textStyle: {
                fontWeight: 300,
                fontSize: 16    //文字的字体大小
              },
              formatter: function (d) {
                return d.value
              }
            },
            data: this.systemTag,

            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }

        ]
      });
    },
    tag_pie(){
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById('tag-pie'));
      // 绘制图表
      myChart.setOption({

        tooltip: {
          trigger: 'item'
        },

        series: [
          {
            type: 'pie',
            radius: '90%',
            label: {            //饼图图形上的文本标签
              show: true,
              position: 'outside', //标签的位置
              textStyle: {
                fontWeight: 300,
                fontSize: 14    //文字的字体大小
              },
              formatter: function (d) {
                return d.name
              }
            },
            data: this.tag,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          },
          {
            type: 'pie',
            radius: '90%',
            label: {            //饼图图形上的文本标签
              show: true,
              position: 'inside', //标签的位置
              textStyle: {
                fontWeight: 300,
                fontSize: 16    //文字的字体大小
              },
              formatter: function (d) {
                return d.value
              }
            },
            data: this.tag,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }

        ]
      });
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .ant-divider{
  margin:0 0 10px 0;
}
h1,h2,h3{
  margin:0;
}
.caseClip_Course{
  margin: -15px;
  background-color: #f0f2f5;
  height: auto;
  .data-count{

    h1{
      margin:0;
    }
    span{
      color: rgba(0, 0, 0, 0.427450980392157);
    }
    display: flex;
    //justify-content: space-between;
    .data-count-day{
      .data-count-day-sum{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .date-month{
    width: 49%;
    margin-right: 20px;
    background: white;
  }
  .date-day{
    margin-top: 20px;
    background: white;
    min-height: 500px;
  }
  .box-header{
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    justify-content: space-between;
  }
  .box-line{
    padding: 10px 20px;
    width: 98%;
    min-height: 400px;
    height: auto;
  }
  .box-pie{
    width: 98%;
    min-height: 400px;
    height: auto;
  }
}
</style>